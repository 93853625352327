var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInformations,
            expression: "showInformations",
          },
        ],
        staticClass: "wrapper",
      },
      [
        _c(
          "div",
          { ref: "cardRef", staticClass: "wrapper__card" },
          [
            _c("div", { staticClass: "wrapper__card__header" }, [
              _vm._m(0),
              _c("div", { staticClass: "right" }, [
                _c(
                  "div",
                  [
                    _c("z-input", {
                      attrs: {
                        placeholder: "Pesquisar",
                        prependIcon: "$search_rounded",
                        type: "text",
                        hideDetails: true,
                      },
                      model: {
                        value: _vm.searchConnection,
                        callback: function ($$v) {
                          _vm.searchConnection = $$v
                        },
                        expression: "searchConnection",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        rounded: "",
                        primary: "",
                        text: "Nova audiência",
                        icon: "mdi-plus",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goToPage("addAudiencePage")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("z-tabs", {
              attrs: { android: "", tabs: _vm.tabs },
              scopedSlots: _vm._u([
                {
                  key: "audience",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "wrapper__card__body mt-3",
                          attrs: { id: "card_body_main" },
                        },
                        [
                          _c("z-pagination", {
                            attrs: {
                              "auto-pagination": "",
                              "icon-previous": "mdi-chevron-left",
                              "icon-next": "mdi-chevron-right",
                              "items-per-page": _vm.getNumberOfElements,
                              "number-of-items": _vm.searchConnection
                                ? _vm.filtered.length
                                : _vm.audiences.lenth,
                              items: _vm.searchConnection
                                ? _vm.filtered
                                : _vm.audiences,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "data",
                                fn: function ({ paginatedItems }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-wrapper" },
                                      [
                                        _c("z-table", {
                                          staticClass: "table",
                                          attrs: {
                                            headers: _vm.headers,
                                            items: paginatedItems,
                                            "icon-next": "mdi-chevron-right",
                                            "icon-previous": "mdi-chevron-left",
                                          },
                                          on: { sort: _vm.sortTable },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "no-data",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "no-data-table",
                                                      },
                                                      [
                                                        _c("h3", [
                                                          _vm._v(
                                                            "Sem dados disponíveis."
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: `item.favorite`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center justify-center",
                                                      },
                                                      [
                                                        item.favorite
                                                          ? _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-not-filled-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.name`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                      },
                                                      [
                                                        item.favorite
                                                          ? _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _c("img", {
                                                              staticClass:
                                                                "mr-1",
                                                              style:
                                                                _vm.iconStyle,
                                                              attrs: {
                                                                src: "/icons/favorite-not-filled-icon.svg",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.favorite(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name ||
                                                                  "Sem registro"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.actions`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "actions",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      1
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$edit_outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Renomear"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      5
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " $edit_rounded"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [_vm._v("Editar")]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      4
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " $refresh_rounded"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Atualizar"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color: "grey",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.selectOptionFromTable(
                                                                                      item,
                                                                                      0
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "$delete_outline_rounded"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "0.5rem",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [_vm._v("Apagar")]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.connectionName`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getConnectionName(
                                                            item
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.created`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formateDate(
                                                            item.created
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                              {
                                                key: `item.status`,
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "badge",
                                                        style:
                                                          _vm.setBadgeTheme(
                                                            item.status ||
                                                              "error"
                                                          ),
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.stats[
                                                                item?.status ||
                                                                  "error"
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "audience-filtered",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "wrapper__card__body mt-3" },
                        [
                          _c("createdFilteredAudiences", {
                            staticStyle: { height: "100%" },
                            attrs: {
                              "search-connection-prop": _vm.searchConnection,
                              "items-per-page-prop": _vm.getNumberOfElements,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c("z-card-modal", {
          attrs: { width: "900", title: "Como você quer começar?" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "cards-audience",
                      staticStyle: {
                        display: "flex",
                        "align-items": "flex-start",
                        gap: "1rem",
                        padding: "1rem 0",
                        "flex-wrap": "wrap",
                        color: "333333",
                      },
                    },
                    _vm._l(_vm.audienceModalData, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "cards-audience__item",
                          style: _vm.cardAudienceStyle,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "header",
                              staticStyle: { "text-align": "center" },
                            },
                            [
                              _c(
                                "figure",
                                {
                                  staticStyle: {
                                    width: "30px",
                                    height: "30px",
                                    margin: "0 auto",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                      "object-fit": "contain",
                                    },
                                    attrs: { src: item.icon },
                                  }),
                                ]
                              ),
                              _c(
                                "strong",
                                {
                                  staticStyle: {
                                    "text-transform": "uppercase",
                                    "font-size": "0.95em",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "body",
                              staticStyle: {
                                "text-align": "center",
                                "font-size": "0.94em",
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(item.description))])]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showAudienceModal,
            callback: function ($$v) {
              _vm.showAudienceModal = $$v
            },
            expression: "showAudienceModal",
          },
        }),
        _c("z-card-modal", {
          attrs: { width: "400", title: "" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "delete-confirmation" }, [
                    _c(
                      "div",
                      { staticClass: "delete-confirmation__title mb-5" },
                      [
                        _c("span", [
                          _vm._v("Tem certeza que deseja apagar a conexão"),
                        ]),
                        _c("br"),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.audienceToDelete?.name ||
                                "Sem nome resgistrado"
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "delete-confirmation--actions" },
                      [
                        _c("z-btn", {
                          attrs: { small: "", rounded: "", text: "cancelar" },
                          on: {
                            click: function ($event) {
                              _vm.dialogDelete = false
                              _vm.resetaudienceToDelete()
                            },
                          },
                        }),
                        _c("z-btn", {
                          attrs: {
                            rounded: "",
                            primary: "",
                            text: "apagar",
                            small: "",
                            isLoading: _vm.loadingDelete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmedDeleteAudience()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        }),
        _c("z-card-modal", {
          attrs: { width: "400", title: "" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _vm.audienceToUpdate?.id
                    ? _c("div", { staticClass: "delete-confirmation" }, [
                        _c(
                          "div",
                          { staticClass: "delete-confirmation__title mb-1" },
                          [
                            _c("span", { staticClass: "mb-5" }, [
                              _vm._v("Insira o novo nome para a conexão:"),
                            ]),
                            _c("z-input", {
                              staticClass: "mt-3",
                              model: {
                                value: _vm.audienceToUpdate.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.audienceToUpdate, "name", $$v)
                                },
                                expression: "audienceToUpdate.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "delete-confirmation--actions" },
                          [
                            _c("z-btn", {
                              attrs: {
                                small: "",
                                rounded: "",
                                text: "cancelar",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialogRename = false
                                  _vm.resetAudienceToRename()
                                },
                              },
                            }),
                            _c("z-btn", {
                              attrs: {
                                rounded: "",
                                primary: "",
                                text: "Renomear",
                                small: "",
                                isLoading: _vm.loadingRename,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmUpdateNameAudience()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogRename,
            callback: function ($$v) {
              _vm.dialogRename = $$v
            },
            expression: "dialogRename",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showInformations,
            expression: "!showInformations",
          },
        ],
        staticClass: "wrapper no-data",
      },
      [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v("Consultar audiências")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }